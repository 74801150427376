 .accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  background: var(--color-dark-gray-50);
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-sm); /* 142.857% */
}

.accordion-header:hover {
  background: var(--color-gray-100);
}

.accordion-header.open {
  background: var(--color-gray-100);
}

.accordion-leftHeader{
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 80%;
  position: relative;
}

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px; 
  align-items: center;
  font-size: 12px;
  text-align: center;
  border-radius: 16px;
  line-height: 18px;
  border: 1px solid var(--color-gray-200);
  background: var(--color-dark-gray-50);
  position: absolute;
  right: -35px;
  top: -2px;
}

.accordion-title {
  font-size: 12px;
}

.accordion-title.NotOpen{
  font-size: 12px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow-icon {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out; /* the open animation */
}

.arrow-icon.open {
  transform: rotate(180deg); /* open rotation */
}

.accordion-content {
  padding: 12px;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: scaleY(0.9); /* default small ==> open animation */
  transform-origin: top; /* the animation effeact will start from top */
}

.accordion-content.show {
  max-height: 100000px; /* the max height */
  opacity: 1; /* all dispolay */
  transform: scaleY(1); /* add the open animation */
  gap: 12px;
}

.accordion-content.hide {
  max-height: 0; /* the hight is zero ==> hide it */
  opacity: 0; /* not display */
  transform: scaleY(0.9); /* animation closed */
}