/* 3.1: the notification component: used for first search, error search, and default status */
.search-input-container {
    display: flex;
    width: 540px;
    min-width: 440px;
    gap: 20px;
    padding: var(--space-3xl);
    border-radius: 8px;
    background: var(--color-dark-gray-50);
    flex-direction: column;
    justify-content: center;
    align-self: center;
    user-select: none;
}

.search-input-container .title{
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-xl);
    color: var(--color-gray-900);
    margin: 0px;
}

.search-input-container .text {
    font-size: var(--font-size-text-md);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-xl);
    color: var(--color-gray-600);
    margin: 0px;
}

.search-input-container .svgIcon {
    width: 48px;
    height: 48px;
    display: flex;
    margin-top: 0px;
    justify-content: center;
    align-items: center;
    background-color: var(--brand-secondary-500);
    border-radius: var(--radius-lg);
}

.search-input {
    flex: 1;
    padding: 8px 16px;
    margin-right: var(--space-lg);
    width: 75%;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    height: 21px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s;
    font-family: var(--font-family-display);
}

.emailText {
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-sm);
    text-decoration: none;
    color: var(--brand-secondary-700);
    cursor: pointer;
}