/* the page navigation button used for all the achieve moduel data chart card item list */
.pageButton {
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

/*1. the square button */
.pageButton.isSquare{
    border-radius: 4px;
}

/*2. the circle button */
.pageButton.isCircle{
    border-radius: 20px;
}

/* the hover effect of square button */
.pageButton.isSquare:hover{
    background: var(--color-dark-gray-50);
}

/* the hover effect of circle button */
.pageButton.isCircle:hover{
    background: var(--color-dark-gray-50);
}


/* the active effect of square button */
.pageButton.isSquare:active{
    background: var(--color-dark-gray-50);
    box-shadow: 0px 0px 0px 2px #FFF, 0px 0px 0px 4px var(--Colors-Secondary-400, #2ED3B7);
}

/* the active effect of circle button */
.pageButton.isCircle:active{
    background: var(--color-dark-gray-50);
    box-shadow: 0px 0px 0px 2px #FFF, 0px 0px 0px 4px var(--Colors-Secondary-400, #2ED3B7);
}

/*the disabled status button */
.pageButton.disabled {
    cursor: not-allowed; /* not allowed click */
    opacity: 0.5;  
    pointer-events: none; /* not allowed click */
}