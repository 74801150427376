.issue-trends {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0;
    font-family: var(--font-family-display);
    align-self: stretch;
}

/* THE BAR container area */
.bar-container {
    display: flex;
    width: 100%;
    background-color: #e0e0e0;
    overflow: hidden;
}

.bar {
    transition: width 0.3s ease;
}

/* the legend item area */
.legend {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    gap: 32px;
}

.legend-item {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.color-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}

.label {
    margin-right: 4px;
    color: var(--color-gray-600);
    font-size: var(--font-size-xxs);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-xxs);
    /* 160% */
}

.occurrences {
    margin-right: 4px;
    color: var(--color-gray-600);
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-sm);
    /* 160% */
}

.percentage {
    margin-right: 4px;
    color: var(--color-gray-600);
    font-size: var(--font-size-xxs);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-xxs);
    /* 160% */
}