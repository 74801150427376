/* the filter menu used of Achieve module Trends Page*/
.TrendsFilter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.SearchArea{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--font-family-display);
}

.viewByArea {
    display: flex;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 34px;
    width: 56px;
    flex: 1 0 0;
    align-self: stretch;
    border-top: 1px solid var(--color-gray-200);
    border-bottom: 1px solid var(--color-gray-200);
    background: var(--color-gray-200);
    color: var(--color-gray-500);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-xxs);
    /* 160% */
}