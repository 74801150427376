@import "../../../App.css";

.badge {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-family: var(--font-family-body);
  font-style: normal;
  border-radius: var(--radius-xxs);
}

/* Status Badge */
.badge--status {
  padding: 2px 4px;
  border: 1px solid var(--color-gray-300);
  background: var(--color-white);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--color-gray-700);
  font-size: var(--font-size-xs);
  height: 24px;
  box-sizing: border-box;
}

.badge__status_dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.badge__status_dot--Current {
  background-color: var(--warning-500); /*the current status dot color of column status*/
}

.badge__status_dot--Closed {
  background-color: var(--success-500);  /*the closed status dot color of column status*/
}

.badge__status_dot--Open {
  background-color: var(--danger-500);  /*the Open status dot color of column status*/
}

.badge__status_dot--Other {
  background-color: var(--blue-500); /*the general status dot color of column status*/
}

/* Default Badge */
.badge--default {
  padding: 1px 4px;
  border: 1px solid var(--color-gray-300);
  background: var(--color-white);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--color-gray-700);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xs);
  max-height: 19px;
}

/*used for the gener page hover effect*/
.badge--default.badge--areaDefault:hover {
  border-color: var(--danger-300);
  background: var(--danger-50);
}

/*used for the storybook display the hover effect layout*/
.badge--default.badge--hovered {
  border-color: var(--danger-300);
  background: var(--danger-50);
}

/* Default Badge selected UI display*/
.badge--default.badge--selected {
  border-color: var(--danger-300);
  background: var(--danger-200);
}

/*the default gray used for share save hiden Query*/
.badge--defaultGray {
  padding: 0px 4px;
  border-radius: 2px;
  background: var(--color-gray-200);
  color: var(--color-gray-800);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-sm);
}

/* Input Badge */
.badge--input {
  padding: 0px 4px;
  background: var(--color-gray-200);
}

/* Input Badge hover effect using in the general page */
.badge--input.badge:hover {
  background: var(--color-gray-300);
  border: 1px solid var(--color-gray-300);
}

/* Input Badge hover effect using in storybook */
.badge--input.badge--hovered {
  background: var(--color-gray-300);
  border: 1px solid var(--color-gray-300);
}

.badge--input .badge__close {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 16px;
  line-height: 1;
}

/* Input Badge with background white */
.badge--inputWhite {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  padding: 2px 4px 2px 9px;
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
  background: var(--color-base-white);
  font-size: var(--font-size-xs); /* Font size */
  font-style: normal; /* Font style */
  font-weight: var(--font-weight-medium); /* Font weight */
  line-height: var(--line-height-xs); /* Line height */
  color: var(--color-gray-700);
}

.badge--inputWhite.badge--hovered {
  background: var(--color-base-white);
}

.badge--inputWhite .badge__close {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 16px;
}

/* Highlight Badge (why need changed to too small??) */
.badge--highlight {
  padding: 0px 8px;
  height: 20px;
  box-sizing: border-box;
  color: var(--color-gray-900);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-sm);
}

.badge--highlight.badge--failure-mode {
  border: 1px solid var(--danger-300);
  background: var(--danger-300);
}

.badge--highlight.badge--components {
  border: 1px solid var(--brand-300);
  background: var(--brand-300);
}

.badge--highlight.badge--failure-condition {
  border: 1px solid var(--warning-200);
  background: var(--warning-200);
}

.badge--highlight.badge--after-effects {
  border: 1px solid #83c7ff;
  background: #83c7ff;
}

.badge--highlight.badge--specifications {
  border: 1px solid var(--success-400);
  background: var(--success-400);
}

/* File Badge */
.badge--file {
  height: 32px;
  padding: 1px 8px;
  border: 1px solid var(--color-gray-300);
  background: var(--color-white);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--color-gray-700);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xs);
  cursor: pointer;
}

/*the hover effect when user click the file badge*/
.badge--file:hover {
  background: var(--color-gray-100);
}

.badge--file.badge--hovered {
  background: var(--color-gray-100);
}

/* Disabled state */
.badge--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.badge--highlight.badge--disabled.badge--failure-mode {
  border-color: var(--danger-100);
  background: var(--danger-100);
}

.badge--highlight.badge--disabled.badge--components {
  border-color: var(--brand-100);
  background: var(--brand-100);
}

.badge--highlight.badge--disabled.badge--failure-condition {
  border-color: var(--warning-50);
  background: var(--warning-50);
}

.badge--highlight.badge--disabled.badge--after-effects {
  border-color: #cae7ff;
  background: #cae7ff;
}

.badge--highlight.badge--disabled.badge--specifications {
  border-color: var(--success-50);
  background: var(--success-50);
}

.badge__icon {
  display: flex;
  align-items: center;
}

.badge__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Input Badge */
.badge__input {
  background: transparent;
  border: none;
  outline: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
  width: 100%;
  min-width: 50px;
}

.badge__input:focus {
  outline: 1px solid var(--color-gray-300);
  background: var(--color-white);
}

/* Category Badge and color item list*/
.badge--category{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  border-radius: 2px;
  border: 1px solid #E4E7EC;
  font-family: var(--font-family-display);
  font-size: var(--Font-size-text-xxs, 10px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Font-height-text-xxs, 16px); /* 160% */
} 

.badge--category.badge--category-gray{
  color: var(--color-dark-gray-700);
  border: 1px solid var(--color-dark-gray-200);
  background: var(--color-dark-gray-50);
}

.badge--category.badge--category-purple{
  color: var(--brand-700);
  border: 1px solid var(--brand-200);
  background: var(--brand-50);
}

.badge--category.badge--category-red{
  color: var(--danger-700);
  border: 1px solid var(--danger-200);
  background: var(--danger-50);
}

.badge--category.badge--category-yellow{
  color: var(--warning-700);
  border: 1px solid var(--warning-200);
  background: var(--warning-50);
}

.badge--category.badge--category-green{
  color: var(--success-700);
  border: 1px solid var(--success-200);
  background: var(--success-50);
}

.badge--category.badge--category-teal{
  color: var(--brand-secondary-700);
  border: 1px solid var(--brand-secondary-200);
  background: var(--brand-secondary-50);
}

.badge--category.badge--category-blue{
  color: var(--blue-700);
  background: var(--blue-50);
  border: 1px solid var(--blue-200);
}

/* Tag Priorities Badge and color item list (Hight, Medium, and Low) */
.badge--priorities{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 2px;
  border: 1px solid transparent;
  color: var(--color-dark-gray-700);
  font-family: var(--font-family-display);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 600;
  line-height: var(--line-height-xxs); /* 160% */
}

/* Priorities Hight: */ 
.badge--priorities.badge--PrioritiesHigh{
  border-color: var(--danger-300);
  background: var(--danger-300);
}

/* Priorities Medium: */ 
.badge--priorities.badge--PrioritiesMedium{
  border-color: var(--warning-200);
  background: var(--warning-200);
}

/* Priorities Low: */ 
.badge--priorities.badge--PrioritiesLow{
  border-color: var(--success-300);
  background: var(--success-300);
}

