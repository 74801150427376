.tippy-box[data-theme~='custom'] {
    background-color: white;
    color: #1f2937;
    font-size: 12px;
    padding: 2px;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    border: 1px solid #e5e7eb;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 9999;
  }
  
  .tippy-box[data-theme~='custom'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: white;
  }
  
  .tippy-box[data-theme~='custom'] .tippy-content {
    padding: 8px 10px;
    line-height: 1.4;
  }
  
  [class$="-wrapper"] {
    display: inline;
  }