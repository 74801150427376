@import "../discover.css";
/*********************   1. The control area: the Highlight Button && the Tab button control open the key specification area   **********************/
.controlArea{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.searchResultMessage{
  color: #101828;
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: var(--font-family-display);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-md);
  margin: 0px;
}

/*button default */
.searchResultMessage .overviewButton{
  display: flex;
  padding: 8px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
}

/*hover effect */
.searchResultMessage .overviewButton:hover{
  background: var(--color-gray-100);
}

/*click effect */
.searchResultMessage .overviewButton:active{
  background: var(--color-gray-100);
}

/*open effect */
.searchResultMessage .overviewButton.isactive{
  background: var(--color-gray-100);
}

.columnSearchArea {
  display: flex;
  height: 10px;
  padding: 10px 8px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  margin: 8px 6px;
  width: calc(100% - 28px);
  
  border-radius: 4px;
  border: 1px solid var(--color-gray-200);
  background: var(--Colors-Base-white, #FFF);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}


/* 1.2: The key specification tab button to control open the right information area or not*/
.ToggleButton{
  display: flex;
  height: 32px;
  box-sizing: border-box;
  padding: 10px var(--font-size-sm);
  align-items: center;
  gap: var(--spacing-md);
  align-self: stretch;
  border-radius: var(--radius-xs);
  border: 1px solid var(--color-gray-300);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  white-space: nowrap;
  margin-left: 12px;
}

.ToggleButton .titleToggle{
  color: var(--color-gray-800); /* Rebecca want to the color more black in the text */
  text-overflow: ellipsis;
  /* Text md/Regular */
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xs); 
  margin-right: 8px;
  margin-top: 0px;
  margin-bottom: 0px;
}


/*********************   2. The issue List area (left): star button, customize tabel button  **********************/
.issueItemArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242424;
  align-self: stretch;
  height: 48px;
  margin-top: 12px;
  padding: 8px 12px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border: 1px solid var(--color-gray-200);
  border-bottom: none;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  position: relative;
}


/* 2.1: the customize table dropdown menu selection item layout */
.cutomizeDropDownMenu::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, var(--color-base-white), transparent);
  pointer-events: none;
}

.cutomizeDropDownMenu {
  flex: 1;
  overflow-y: auto;
  max-height: 250px;
}

.cutomizeDropDownMenu::-webkit-scrollbar {
  width: 5px;
  padding: 4px;
}

.cutomizeDropDownMenu::-webkit-scrollbar-thumb {
  background: var(--color-gray-500);
}

.cutomizeDropDownMenu::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-600);
}

.cutomizeDropDownMenu::after {
  display: none;
}

/* 2.2: the view button of issue table: Star, Hidden, and Saved*/
.ViewButtonArea{
  display: flex;
  gap: 4px;
  padding: 6px;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--radius-xs);
  background: var(--color-dark-gray-50);
}

/* 2.3: the keyboard feature menu display all the keyboard feature of issue table */
.rightButtonArea{
  display: flex;
  align-items: center;
  gap: 10px;
}

.keyBoardButton{
  display: flex;
  padding: 8px;
  height: 32px;
  width: 22px;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-xs);
  background: var(--color-dark-gray-50);
  cursor: pointer;
}

.keyBoardButton:active{
  background: var(--color-dark-gray-200);
}

.keyBoardButton svg{
  width: 24px !important;
  height: 24px !important;
  user-select: pointer;
}

.keyboardDropdownMenu {
  position: absolute;
  right: 220px;
  top: 100%;
  border-radius: 4px;
  min-width: 400px;
  max-width: 400px;
  min-height: 220px;
  max-height: 220px;
  padding: 6px;
  border: 1px solid var(--color-gray-200);
  background: var(--color-base-white);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-body);
  z-index: 0;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.keyboardDropdownMenu.show {
  transform: scale(1);
  opacity: 1;
  z-index: 9999;
}

.keyboardDropdownMenu .featureItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-gray-700);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
  line-height: var(--font-size-md); /* 142.857% */
  padding: 6px;
}

.KeyBoardIcon {
  display: flex;
  min-width: 12px;
  width: auto;
  height: 12px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--color-gray-100);
}


/**************** 3. the realative issue data table: below the switch bar to disaply all the relative issue details as format*****************/
.issue-areaFormat {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* 3.1: the first search notification pop window */
.search-input-area{
  width: 100%;
  height: 80%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input-container {
    display: flex;
    width: 540px;
    min-width: 440px;
    gap: 20px;
    padding: var(--space-3xl);
    border-radius: 8px;
    background: var(--color-dark-gray-50);
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.search-input-container h2{
    font-size: var(--font-size-text-xl);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-xl);
    margin: 0px;
}

.search-input-container .svgIcon{
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--brand-secondary-500);
    border-radius: var(--radius-lg);
}

.search-input {
    flex: 1;
    padding: 8px 16px;
    margin-right: var(--space-lg);
    width: 75%;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    height: 21px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s;
    font-family: var(--font-family-display);
}

/* 3.3: the search loading process effect "Apiphany AI is Searching for Relative Issue..." with dots circle animation*/
.issue-areaFormat p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-color);
}

/*the green process animation effect*/
.fade-in {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease-in;
}

.dots-loader {
  position: relative;
  width: 58px;
  height: 58px;
  animation: rotate 16s linear infinite;
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--brand-secondary-400);
  transform-origin: 0 0;
  animation: fade 2s linear infinite;
}

.fade-in p{
  font-family: var(--font-family-display);
  color: var(--color-gray-600);
  font-size: var(--font-size-display-xs);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-display-xs); /* 133.333% */
}


@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0%, 100% {
    opacity: 0.16;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*the green process animation effect*/

/* ************************ 4. The issue detials pop window that user selected similar issue for display  *************************/
.issueDetailsModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  max-width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: var(--Colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10));
  /* Makes the modal container not block clicks */
}

.issueDetailsArea {
  display: flex;
  margin-top: 4%;
  margin-left: auto;
  margin-right: auto;
  width: 730px;
  min-width: 730px;
  max-width: 830px;
  max-height: 75vh;
  background: var(--Neutral-Background-1-Rest, #fff);
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Stroke-Transparent-Rest, rgba(255, 255, 255, 0));
  box-shadow:
    0px 32px 64px 0px rgba(0, 0, 0, 0.24),
    0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  pointer-events: all;
  cursor: default;
  user-select: text;
}

.issueDetailsArea:active {
  cursor: text;
}

/*the relative issue pop window header tittle area*/
.detailsHeader{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.detailsHeader h2{
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-800);
}

.issueDetailsArea h2 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0;
  line-height: 28px;
  color: var(--Neutral-Foreground-2-Rest, #424242);
}

/*the issue details menu switch: details, timeline, and commen */
.realativeIssueDetailsMenu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-right: 16px;
  white-space: nowrap;
  gap: 4px;
}

/*the background color was white */
.issueDetailsArea .issueFormat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  align-self: stretch;
  width: 100%;
  gap: 12px;
}

.issueDetailsArea .issueFormatChild {
  display: flex;
  padding: 16px 12px;
  overflow-x: hidden;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: #F9FAFB;
  gap: 16px;
}

.issueFormat h3{
  color: var(--color-gray-900);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-sm); 
  margin-right: 6px; 
  margin-top: 0px;
  margin-bottom: 0px;
}

.issueFormat h2{
  color: var(--color-gray-600);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-size-display-xs); 
  margin-right: 6px; 
  margin-top: 0px;
  margin-bottom: 4px;
}

.issueFormat .downloadText{
  color: var(--brand-secondary-700);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-size-display-xs); 
  margin-right: 6px; 
  margin-top: 0px;
  margin-bottom: 4px;
  cursor: pointer;
}

.issueDetailsRowDisplay {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: var(--color-gray-900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
}

.issueDetailsColumnDisplay{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  color: var(--color-gray-900);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-sm);
   
  white-space: pre-wrap; /* Preserves line breaks in the content */

}

.detailsAreaValue {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding-bottom: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-color);
  white-space: pre-wrap;
}

.areaItem {
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--color-gray-300);
  padding: 1px 4px;
  white-space: nowrap; /* not change new line*/
  overflow: hidden;
  text-overflow: ellipsis; /* overside, it will hidden and display "..." */
}

.attachmentsArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-wrap;
  gap: 12px;
  margin-bottom: 12px;
}

.attachmentsArea svg{
  display: flex;
  justify-content: center;
  align-items: center;
}

/*the background color was white */
.issueDetailsArea .issueGrayFormat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  align-self: stretch;
   
  padding: 12px;
  gap: 8px;
  background-color: var(--color-dark-gray-50);
}

.draggableHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  background: transparent;
  z-index: 1;
}

