/* the module 2: Discover module page css file */
@import "../../App.css";

.discover{
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}

/*********************   1. The issue List area (left): including search area, menu button, and list area **********************/
.issue-area {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: calc(100vh - 62px);
  max-width: calc((100vw - 32px));
  transition: width 0.5s;
  position: relative;
  padding-right: 6px;
  margin: 0;
  background-color: white;
  box-sizing: border-box;
  /*the width and height will including the border value */
}

/************************* 2. The specifications area (right): specifications information and other information part *************************/
.demoAreaBox {
  flex: 1;
  transition:
    width 0.3s,
    margin-left 0.3s;
  height: 100%;
  min-height: calc(100vh - 77px);
  max-height: calc(100vh - 77px);
  max-width: calc(32vw - 25px);
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 18px;
  overflow-y: hidden;
  padding: 4px 0px;
  position: relative;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  /*the width and height will including the border value */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  border: 1px solid var(--Colors-Border-border-secondary); 
  overflow: hidden;
}
