.searchArea{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.searchField {
  display: flex;
  padding: 3px 14px;
  align-items: center;
  height: 28px;
  justify-content: space-between;
  gap: 8px;
  flex: 1;
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-family: var(--font-family-body);
}

.searchField:focus-within {
  border-color: var(--brand-secondary-400);
}

.searchContent {
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  gap: 8px;
}

.searchTermsContainer {
  max-width: 300px;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.searchTerms {
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 4px 0;
}

.searchInput {
  flex: 1;
  align-self: stretch;
  position: relative;
  border: none;
  background: transparent;
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
  outline: none;
  margin-left: 8px;
}

.searchInput::placeholder {
  color: var(--color-gray-500);
}

.dropDown {
  position: absolute;
  top: 36px;
  left: 0px;
  width: calc(100% - 85px);
  align-self: stretch;
  max-height: 180px;
  background: white;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  overflow-y: auto;
  z-index: 100;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropDownRow {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  border-radius: 0 50px 50px 0;
}

.dropDownRow:hover {
  background-color: #f5f5f5;
  border-radius: 0 50px 50px 0;
}

.searchActions {
  display: flex;
  align-items: center;
  
}

.clearButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clearIcon {
  width: 20px;
  height: 20px;
  color: var(--color-gray-600);
  padding: 4px;
  border-radius: var(--radius-full, 9999px);
  transition: background-color 0.2s ease-in-out;
}

.clearButton:hover .clearIcon,
.clearButton:focus .clearIcon {
  background: var(--colors-gray-light-mode-200, #e4e7ec);
}

.clearButton:focus {
  outline: none;
}

.searchTerms::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.searchTerms {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.filterMenuFixedTop {
  position: sticky;
  top: 0;
  background-color: var(--color-base-white);
  z-index: 1;
}

.filterMenuFixedBottom {
  position: sticky;
  bottom: 0;
  background-color: var(--color-base-white);
  z-index: 1;
}

/* Optional: Add fade effect for scroll indication */
.filterMenuScrollable::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, var(--color-base-white), transparent);
  pointer-events: none;
}

/* Scrollbar styles for filter menu */
.filterMenuScrollable {
  flex: 1;
  overflow-y: auto;
}

/* Webkit scrollbar styles */
.filterMenuScrollable::-webkit-scrollbar {
  width: 8px;
  padding: 4px;
}

.filterMenuScrollable::-webkit-scrollbar-thumb {
  background: var(--color-gray-200);
  border-radius: 1000px;
}

.filterMenuScrollable::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-200);
}

/* Remove the fade effect if you have it */
.filterMenuScrollable::after {
  display: none;
}

.filterMenuContent {
  padding: 0px 0px !important;
  width: 500px !important;
  height: 400px !important;
  position: relative !important;
  border-radius: 4px !important;
  overflow: hidden !important;
}

/* the search filter menu UI attribute */
.filterMenuContainer {
  display: flex;
  flex-direction: row !important;
  height: 400px;
  width: 100%;
}

.filterMenuMain {
  width: 250px;
  min-width: 250px;
  padding: 4px 0px;
  border-right: 1px solid var(--color-gray-200);
}

.filterMenuSub {
  width: 250px;
  padding: 4px 0px;
  display: flex;
}

.filterMenuSubContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--color-base-white);
}

.filterMenuSubContentItem {
  color: var(--color-gray-700);
  font-family: var(--font-family-body);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-sm);
}

.filterMenuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.filterMenuItem:hover {
  background-color: var(--color-secondary-100);
}

.filterMenuItem.active {
  background-color: var(--color-secondary-100);
}

.filterMenuCheckbox {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
}

.filterMenuCheckbox:hover {
  background-color: var(--color-secondary-100);
}

.filterMenuFixedTop,
.filterMenuFixedBottom {
  position: sticky;
  background: var(--color-base-white);
}

.filterMenuFixedTop {
  top: 0;
}

.filterMenuFixedBottom {
  bottom: 0;
}

.filterMenuScrollable {
  overflow-y: auto;
  flex: 1;
  min-height: 0; /* This helps with flexbox scrolling */
}

.filterMenuSeparator {
  height: 1px;
  background-color: var(--color-gray-200);
  margin: 8px 0;
}
