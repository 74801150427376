@import "../../../App.css";

.button {
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--font-family-body);
  font-weight: var(--font-weight-semibold);
  transition: background-color 0.3s;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

/**************** ==> Size variants <== ****************/
.button--xsmall {
  font-size: 12px;
  height: 24px;
  padding: 8px var(--space-lg);
}
.button--small {
  font-size: 14px;
  height: 32px;
  padding: 8px var(--space-lg);
}
.button--medium {
  font-size: 14px;
  height: 40px;
  padding: var(--font-size-xxs) var(--font-size-sm);
}
.button--large {
  font-size: 16px;
  height: 54px;
  padding: var(--font-size-xxs) var(--font-size-md);
}

/**************** ==> icon button <== ****************/
.button--icon-only {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; /* removed default padding size */
}

/* small size icon with 8px */
.button--xsmall.button--icon-only {
  height: 24px;
  width: 24px;
  padding: 8px;
}

.button--xsmall.button--icon-only svg{
  width: 8px;
  height: 8px;
}

/* small size icon with 8px */
.button--small.button--icon-only {
  height: 32px;
  width: 30px;
  padding: 8px;
}

.button--small.button--icon-only svg{
  width: 14px;
  height: 14px;
}

/* the medium icon with 10px */
.button--medium.button--icon-only {
  height: 40px;
  width: 40px;
  padding: 10px;
}

.button--medium.button--icon-only svg{
  width: 20px;
  height: 20px;
}

/* the large icon with 12px */
.button--large.button--icon-only {
  height: 44px;
  width: 44px;
  padding: 12px;
}

.button--large.button--icon-only svg{
  width: 20px;
  height: 20px;
}

/*The General Button: */
/*************** 1. Primary Button ********************/
.button--primary {
  border: none;
  background-color: var(--color-gray-900);
  color: var(--color-base-white);
}
.button--primary:hover {
  background-color: var(--color-gray-600);
}
.button--primary.button--disabled {
  background-color: var(
    --color-gray-100
  ); /* Custom disabled color for primary */
  color: var(--color-gray-400);
  border: 1px solid var(--color-gray-200);
  cursor: not-allowed;
}

/******************** 2. Secondary Button ********************/
.button--secondary {
  background-color: var(--color-white);
  color: var(--color-gray-700);
  border: 1px solid var(--color-gray-300);
}
.button--secondary:hover {
  background-color: var(--color-dark-gray-50);
}
.button--secondary.button--disabled {
  background-color: var(
    --color-white
  ); /* Custom disabled color for secondary */
  color: var(--color-gray-400);
  border-color: var(--color-gray-100);
  cursor: not-allowed;
}

/********************* 3. Secondary Color Button *********************/
.button--secondary-color {
  background-color: var(--brand-secondary-200);
  color: var(--brand-secondary-800);
  border: 1px solid var(--brand-secondary-200);
}
.button--secondary-color:hover {
  background-color: var(--brand-secondary-400);
  color: var(--brand-secondary-800);
  border: 1px solid var(--brand-secondary-400);
}
.button--secondary-color.button--disabled {
  background-color: var(
    --color-white
  ); /* Custom disabled color for secondary-color */
  color: var(--color-gray-400);
  border-color: var(--color-gray-100);
  cursor: not-allowed;
}

/********************* 4. Tertiary Gray Button *********************/
.button--tertiary-gray {
  border: none;
  background-color: transparent;
  color: var(--color-gray-600);
}
.button--tertiary-gray:hover {
  background-color: var(--color-dark-gray-50);
}
.button--tertiary-gray.button--disabled {
  color: var(--color-gray-400);
  cursor: not-allowed;
}

/********************* 5. Tertiary Color Button *********************/
.button--tertiary-color {
  border: none;
  background-color: transparent;
  color: var(--brand-secondary-700);
}
.button--tertiary-color:hover {
  background-color: var(--brand-secondary-50);
}
.button--tertiary-color.button--disabled {
  color: var(--color-gray-400);
  cursor: not-allowed;
}

/********************* 6. Link Gray Button *********************/
.button--link-gray {
  border: none;
  background-color: transparent;
  color: var(--color-gray-600);
}
.button--link-gray:hover {
  color: var(--color-gray-800);
}
.button--link-gray.button--disabled {
  color: var(--color-gray-400);
}

/********************* 7. Link Color Button *********************/
.button--link-color {
  border: none;
  background-color: transparent;
  color: var(--brand-secondary-700);
}
.button--link-color:hover {
  color: var(--brand-secondary-800);
}
.button--link-color.button--disabled {
  color: var(--color-gray-400);
}

/*The Danger Button: */
/*************** 1. Danger Primary Button ********************/
.button--danger-primary {
  border: none;
  background-color: var(--danger-500);
  color: white;
}
.button--danger-primary:hover {
  background-color: var(--danger-600);
}
.button--danger-primary.button--disabled {
  background-color: var(
    --color-gray-100
  ); /* Custom disabled color for primary */
  color: var(--color-gray-400);
  border: 1px solid var(--color-gray-200);
  cursor: not-allowed;
}

/*************** 2. Danger Secondary Button ********************/
.button--danger-secondary {
  background-color: var(--color-white);
  color: var(--danger-700);
  border: 1px solid var(--danger-300);
}
.button--danger-secondary:hover {
  background-color: var(--danger-50);
}
.button--danger-secondary.button--disabled {
  background-color: var(
    --color-white
  ); /* Custom disabled color for secondary-color */
  color: var(--color-gray-400);
  border-color: var(--color-gray-100);
  cursor: not-allowed;
}

/*************** 3. Danger Tertiary Button ********************/
.button--danger-tertiary {
  border: none;
  background-color: transparent;
  color: var(--danger-600);
}
.button--danger-tertiary:hover {
  background-color: var(--danger-50);
}
.button--danger-tertiary.button--disabled {
  color: var(--color-gray-400);
  cursor: not-allowed;
}

/*************** 4. Danger Link Button ********************/
.button--danger-link {
  border: none;
  background-color: transparent;
  color: var(--danger-500);
}
.button--danger-link:hover {
  color: var(--danger-700);
}
.button--danger-link.button--disabled {
  color: var(--color-gray-400);
}
