/* the format card: used in Achieve module Issue overview part manufacturer and system format */
.FormatCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family-display);
    border: 1px solid var(--colors-gray-light-mode-100, #F2F4F7);
    transition: width 0.4s ease;
}

.FormatTitleArea {
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;
    border-bottom: 1px solid #F2F4F7;
}

/* The chartDataDeatils title */
.FormatTitle{
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs); /* 142.857% */
    color: var(--color-gray-600);
}

/* the "views deatils" button UI style */
.detailsText{
    color: var(--blue-600);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs); /* 150% */
    cursor: pointer;
}

/* the chart area including chart */
.FormatArea {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* the right side not move */
    padding: 12px 24px;
    height: 100%;
    max-height: 100%;
    align-self: stretch;
    align-items: flex-start;
    background: var(--color-base-white);
    transition: width 0.4s ease;
    overflow: hidden;
    border-radius: 0px 0px 4px 4px;
    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(10, 13, 18, 0.05);
}

/* Format Header (Table Header) */
.FormatHeader {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    background: white;
    align-self: stretch;
    border-bottom: 1px solid var(--colors-gray-light-mode-100, #F2F4F7);
    padding-right: 5px;
}

.FormatColumn{
    display: flex;
    height: 40px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    color: var(--color-gray-500);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    padding: 12px;
    line-height: var(--line-height-xs); /* 150% */
}

/* Table Body */
.FormatBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100% - 40px);
    overflow-y: auto;
}

.FormatBody::-webkit-scrollbar {
    width: 5px;
}

.FormatBody::-webkit-scrollbar-thumb {
    background: var(--color-gray-500);
}

.FormatBody::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-600);
}

/* Table Row */
.FormatRow {
    display: grid;
    grid-template-columns: 40% 30% 30%;
}

/* Odd Rows (odd with gray color) */
.FormatRow.odd {
    background: #F2F4F7;
}

/* Even Rows (even with white color) */
.FormatRow.even {
    background: white;
}

/* Table Cell */
.FormatCell {
    padding: 16px 12px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs); /* 150% */
    color: var(--color-gray-900);
}

/* First column with click function: will jump to details page */
.FormatCell.isBlue{
    color: var(--blue-600);
    cursor: pointer;
    white-space: nowrap; /* not change text line */
    overflow: hidden; /* hideen the over size part */
    text-overflow: ellipsis;
}
