/* the switch image button used for switch image (next or previous) */
.switchImageButton {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

/*1. the square button */
.switchImageButton.isSquare{
    border-radius: 8px;
}

/*2. the circle button */
.switchImageButton.isCircle{
    border-radius: 20px;
}

/* the hover effect of square button */
.switchImageButton.isSquare:hover{
    background: var(--color-dark-gray-50);
}

/* the hover effect of circle button */
.switchImageButton.isCircle:hover{
    background: var(--color-dark-gray-50);
}


/* the active effect of square button */
.switchImageButton.isSquare:active{
    background: var(--color-dark-gray-50);
    box-shadow: 0px 0px 0px 2px #FFF, 0px 0px 0px 4px var(--Colors-Secondary-400, #2ED3B7);
}

/* the active effect of circle button */
.switchImageButton.isCircle:active{
    background: var(--color-dark-gray-50);
    box-shadow: 0px 0px 0px 2px #FFF, 0px 0px 0px 4px var(--Colors-Secondary-400, #2ED3B7);
}

/*the disabled status button */
.switchImageButton.disabled {
    cursor: not-allowed; /* not allowed click */
    opacity: 0.5;  
    pointer-events: none; /* not allowed click */
}


