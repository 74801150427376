/* The Chart Card used for achieve modul all the chart */
/* Chart Graph: Ahieve module Chart Card without chart data item display list */
.ChartGraph{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family-display);
    border: 1px solid var(--colors-gray-light-mode-100, #F2F4F7);
    transition: width 0.4s ease;
}

/* dropdown menu with View Details button */
.chartGraphMenuArea{
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #F2F4F7;
}

.detailsGraphText{
    color: var(--blue-600);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs); /* 150% */
    cursor: pointer;
}

/* the chart area including chart */
.chartGraphArea{
    display: flex;
    justify-content: flex-end; /* the right side not move */
    padding: 12px 24px;
    height: 100%;
    align-self: stretch;
    align-items: flex-start;
    border-radius: 0px 0px 4px 4px;
    background: var(--color-base-white);
    box-sizing: border-box;
}

/*the selection menu used for the card drop down menu */
.selectionMenuArea::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, var(--color-base-white), transparent);
  pointer-events: none;
}

.selectionMenuArea {
  flex: 1;
  overflow-y: auto;
  max-height: 300px;
}

.selectionMenuArea::-webkit-scrollbar {
  width: 5px;
  padding: 4px;
}

.selectionMenuArea::-webkit-scrollbar-thumb {
  background: var(--color-gray-500);
}

.selectionMenuArea::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-600);
}

.selectionMenuArea::after {
  display: none;
}

 