.IssueBlock {
  height: calc(100vh - 205px);
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-body);
  overflow-y: auto;
  overflow-x: hidden;
  gap: 12px;
  padding: 5px 10px;
}

.IssueBlock::-webkit-scrollbar {
  width: 5px;
}

.IssueBlock::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-500);
  cursor: pointer;
  border-radius: 3px;
}

.IssueBlock::-webkit-scrollbar-track {
  background-color: var(--color-gray-50);
}

.IssueBlock::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-600);
}

.EmptyNotification{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}


.issue-block-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  min-height: 500px;
}

.issue-block-item .queryHeaderArea {
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 16px;
}

.issue-block-item .table-container {
  /* 移除表格顶部的额外边框 */
  border-top: none;
}