/* Format Component: Without Inset Hover Button, and each row could open dropdown menu */

/* Container for the whole table */
.fortmatCompoent {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    overflow: hidden;
    background-color: #fff;
    font-family: var(--font-family-display);
    border-radius: 4px 0px 4px 4px;
}

/* Table Header */
.fortmatCompoent-header {
    display: flex;
    background-color: #F9FAFB;
    align-items: stretch;
    padding-right: 5px;
    border-bottom: 1px solid var(--color-gray-200);
}

.fortmatCompoentHeader-cell {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 44px;
    padding: 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background: #F9FAFB;
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
    position: relative;
}

/* the header area right border effect */
.fortmatCompoentHeader-cell::after {
    content: "";
    position: absolute;
    top: 0;  
    bottom: 0; 
    right: 0;
    width: 1px;
    height: calc(100% - 24px);  
    background: #D0D5DD;
    margin: auto 0; 
}

.fortmatCompoentHeader-cell:last-child::after {
    display: none;
}

/* Table Body */
.formatCompon-body {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 99px);
    overflow-y: auto;
}

.formatCompon-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.formatCompon-body::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-500);
    cursor: pointer;
    border-radius: 3px;
}

.formatCompon-body::-webkit-scrollbar-track {
    background-color: var(--color-gray-50);
}

.formatCompon-body::-webkit-scrollbar-thumb:hover {
    background: var(--color-gray-600);
}

.rowsArea{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formatCompon-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.formatCompon-row.even {
    background-color: #fff;
}

.formatCompon-row.odd {
    background-color: #f9f9f9;
}

/* format body UI */
.formatCompon-cell {
    display: flex;
    min-width: 0;
    flex: 1;
    height: 50px;
    padding: 8px 24px 8px 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--color-gray-200);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
    white-space: nowrap;
}

.formatCompon-cell > span {   
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    flex: 1;
}

/*the column action takens cell */
.actionsTakens-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;  
}

.actionsTakens-cell .nameText {
    min-width: 0;  
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* the occureences button in the column action taken, used for open nested area*/
.occureencesButton {
    flex-shrink: 0; /* make sure the button not be hidden */
    display: flex;
    padding: 2px 4px 2px 9px;
    width: 110px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 4px;
    border: 1px solid var(--color-gray-300);
    background: var(--color-base-white);
    color: var(--color-gray-700);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-xs);
    height: 22px;
    box-sizing: border-box;
}

.Effectiveness-cell {
    display: flex;
    align-items: center;
    gap: 12px;
}

/******************************************** the dropdown area format *********************************************/
.dropdown-menu {
    display: flex;
    height: auto;
    padding: 20px 20px 20px 0px;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    background: var(--color-gray-200);
    box-sizing: border-box;
    width: 100%;
}

.nestedFormat {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    overflow: hidden;
    width: 100%;
    margin-left: 20px;
    background-color: #fff;
    font-family: var(--font-family-display);
    border-radius: 4px 0px 4px 4px;
}

/* Table Header */
.nestedFormat-header {
    display: flex;
    background-color: #F9FAFB;
    align-items: stretch;
    padding-right: 5px;
    border-bottom: 1px solid var(--color-gray-200);
}

.nestedFormatHeader-cell {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 44px;
    padding: 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background: #F9FAFB;
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
    position: relative;
}

/* the header area right border effect */
.nestedFormatHeader-cell::after {
    content: "";
    position: absolute;
    top: 0;  
    bottom: 0; 
    right: 0;
    width: 1px;
    height: calc(100% - 24px);  
    background: #D0D5DD;
    margin: auto 0; 
}

.nestedFormatHeader-cell:last-child::after {
    display: none;
}

/* Table Body */
.nestedFormat-body {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 99px);
    overflow-y: auto;
}

.nestedFormat-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.nestedFormat-row.even {
    background-color: #fff;
}

.nestedFormat-row.odd {
    background-color: #f9f9f9;
}

/* format body UI */
.nestedFormat-cell {
    display: flex;
    min-width: 0;
    flex: 1;
    height: 50px;
    padding: 8px 24px 8px 12px;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid var(--color-gray-200);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    line-height: var(--line-height-xs);
    color: var(--color-gray-900);
    white-space: nowrap;
}

.nestedFormat-cell > span {   
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    flex: 1;
}

.issueKey-cell {
    display: flex;
    align-items: center;
    gap: 12px;
}

.nestedFormatCompon-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    max-height: 54px;
    min-height: 54px;
    box-sizing: border-box;
    border-top: 1px solid #E4E7EC;
    background: var(--color-base-white);
}

.nestedFormatCompon-left {
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-md);
    /* 150% */
    color: var(--color-gray-900);
}

.nestedFormatCompon-right {
    display: flex;
    gap: 10px;
}


/******************************************** the dropdown area format *********************************************/

/* Table Footer */
.formatCompon-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    max-height: 54px;
    min-height: 54px;
    box-sizing: border-box;
    border-top: 1px solid #E4E7EC;
    background: var(--color-base-white);
}

.formatCompon-left {
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-md);
    /* 150% */
    color: var(--color-gray-900);
}

.formatCompon-right {
    display: flex;
    gap: 10px;
}