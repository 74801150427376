/*This is the main CSS file of All the page including: split area, sign-up and login component*/
body {
  background-color: var(--color-white);
  margin: 0;
  overflow: hidden;
  user-select: none;
  font-family: var(--font-family-body);
  outline: none !important;
}

.App {
  background-color: var(--color-white);
  display: flex;
  width: 100vw;
  flex-direction: column;
  height: 100vh;
}

body, html {
  min-width: 1024px !important;
}

/* removed all the broswer auto border*/
input,
textarea,
select,
button {
  border: none;
}

* {
  outline: none !important;
}

/*removed all the broswer auto have border*/
*:focus {
  outline: none;
}

*:hover {
  outline: none;
}

/*add the pointer effect for all the button*/
button {
  cursor: pointer;
}

.layout-container {
  display: flex;
  flex: 1;
}

.content {
  flex: 1;
  height: 100%;
}
