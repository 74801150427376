/* Achieve moduel: Dashboard Part */
.trendsArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100vw - 66px);
    min-height: calc(100vh - 42px);
    max-height: calc(100vh - 42px);
    padding: 24px;
    gap: 12px;
    flex: 1 0 0;
    box-sizing: border-box;
    overflow: hidden;
    font-family: var(--font-family-display);
}

.failureModeChartArea{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.trendsDataFormat{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 12px;
}

.detailWindowTitle{
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: 600;
  line-height: var(--line-height-md);
  color: var(--color-gray-800);
}

.detailWindowText{
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-sm);
  color: var(--color-gray-800);
}

.TrendsArea{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-bottom: 6px;
}

.occureencesButton-icon {
  width: 16px;
  height: 16px;
  transition: transform 0.2s ease-in-out;
}

.occureencesButton-icon.open {
  transform: rotate(180deg);
}