.profile-area {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 42px);
  overflow-y: auto;
  margin-right: 4px;
  margin-top: 1px;
}

.profile-area::-webkit-scrollbar {
  width: 7px;
}

.profile-area::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-300);
  cursor: pointer;
  border-radius: 3px;
}

.profile-area::-webkit-scrollbar-track {
  background-color: #fff;
}

.profile-container {
  min-width: 800px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 24px;
  height: 50vh;
  font-family: var(--font-family-body);
  max-height: calc(100vh - 42px);
}

.section-title {
  font-size: var(--font-size-lg);
  color: var(--color-gray-900);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-lg);
  margin: 0px;
}

.section-description {
  color: var(--color-gray-600);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-sm);
  margin-top: 4px;
  margin-bottom: 24px;
}

.avatarImage {
  width: 96px;
  height: 96px;
  background: var(--color-base-white);
  border-radius: 50%;
  margin-bottom: 32px;
}

.user-image {
  width: 96px;
  height: 96px;
  border-radius: 50%;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: var(--space-4xl);
}

.password-section {
  height: 50vh;
  margin-top: 28px;
}

.password-title {
  color: var(--color-gray-900);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  margin-top: 0px;
}

.password-description {
  color: var(--color-gray-600);
  font-size: 14px;
  margin-bottom: 24px;
}

.password-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.password-requirement {
  font-size: 12px;
  color: var(--color-gray-600);
  margin-top: 4px;
}

.password-requirement.valid {
  color: var(--success-500);
}

.password-requirement.notValid {
  color: var(--danger-600);
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-bottom: 8px;
}

.btn {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: none;
}

@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
}

/*the success notice display information*/
.success-message {
  color: var(--success-500);
  margin: 10px 0;
  padding: 10px;
  background-color: #d4edda;
  border-radius: 4px;
}

