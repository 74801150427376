
/* 
Filer menu (achieve module):
Bag the filter dropdown menu together as a component
the issue overview page tittle (right dropdown menu) 
*/
.issueDetailsTitleAreaRight{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    flex: 1 0 0;
}

/* the dynamic time line Tab button area */
.issueDetailsTitleAreaRight .timeLineArea {
    display: flex;
    padding: 4px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--color-dark-gray-50);
    height: 22px;
}