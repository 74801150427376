/* Date Pick Component: Used for Achieve mdoule Trends page, that user could select the date range */
.datePickerArea{
  display: flex;
  background: white;
  flex-direction: column;
  justify-self: flex-start;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  font-family: var(--font-family-display);
}

.date-picker {
  display: flex;
  background: white;
}

.date-picker-sidebar {
  display: flex;
  width: 128px;
  box-sizing: border-box;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  border-right: 1px solid var(--color-gray-200);
  background: #fff;
  font-family: var(--font-family-display);
}

.date-picker-sidebar button {
  border: none;
  background: transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  font-family: var(--font-family-display);
}

.date-picker-sidebar button.active {
  background: var(--color-gray-50);
}

/*the date pick content area */
.date-picker-content {
  flex: 1;
  display: "flex"; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}

/*******************************************/
.month-year-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
}

.month-display {
  font-size: 16px;
  font-weight: bold;
  margin: 0 12px;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
}

.year-dropdown {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  font-size: 14px;
  cursor: pointer;
}
/*******************************************/
.day-no-bold {
  font-weight: normal !important;
}

.my-range-start, .my-range-end, .my-range-middle, .my-selected {
  font-weight: normal !important;
}

.day-no-bold {
  font-weight: normal !important;
}

/*the footer area of data pick component */
.date-picker-footer {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  padding: 12px;
  font-size: 14px;
  color: var(--color-gray-500);
  gap: 14px;
}

.date-picker-footer input {
  border: 1px solid #D0D5DD;
  box-sizing: border-box;
  height: 32px;
  width: 120px;
  color: var(--color-gray-500);
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}

.date-picker-actions {
  display: flex;
  justify-content: flex-end;
  margin-left: 6px;
  gap: 6px;
}

.rdp-root {
  --rdp-accent-color: #667085; /* Change the accent color to indigo. */
  --rdp-accent-background-color: #f0f0f0; /* Change the accent background color. */
  /* Add more CSS variables here. */
  margin-left: 0;
}


.rdp-day {
  width: 40px !important;
  height: 40px !important;
}

.rdp-day_button{
  width: 40px !important;
  height: 40px !important;
}