@import "../../../App.css";

.checkbox {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox__label {
  display: inline-flex;
  align-items: center;
  font-family: var(--font-family-body);
  font-style: normal;
  font-weight: var(--font-weight-small);
  color: var(--color-gray-700);
  cursor: pointer;
}

.checkbox__control {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;
  cursor: pointer;
}

.checkbox--xs .checkbox__control {
  width: var(--font-size-xxs);
  height: var(--font-size-xxs);
  border-radius: var(--radius-xxs);
}

.checkbox--sm .checkbox__control {
  width: var(--font-size-xs);
  height: var(--font-size-xs);
  border-radius: var(--radius-xxs);
}

.checkbox--md .checkbox__control {
  width: var(--font-size-md);
  height: var(--font-size-md);
  border-radius: var(--radius-xs);
}

.checkbox--radio .checkbox__control {
  border-radius: 50%;
}

.checkbox__icon {
  width: 100%;
  height: 100%;
  fill: var(--color-white);
}

.radio__dot {
  width: 50%;
  height: 50%;
  background-color: var(--color-white);
  border-radius: 50%;
}

.checkbox__input:checked + .checkbox__label .checkbox__control {
  background-color: var(--color-gray-700);
  border-color: var(--color-gray-700);
}

.checkbox__input:not(:disabled):checked:hover
  + .checkbox__label
  .checkbox__control,
.checkbox__input:checked:focus + .checkbox__label .checkbox__control {
  background-color: var(--color-gray-800);
  border-color: var(--color-gray-800);
}

.checkbox__input:indeterminate + .checkbox__label .checkbox__control {
  background-color: var(--color-gray-700);
  border-color: var(--color-gray-700);
}

.checkbox--hover .checkbox__control,
.checkbox__input:not(:disabled):checked:hover
  + .checkbox__label
  .checkbox__control {
  border-color: var(--color-gray-800);
}

.checkbox--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkbox__text {
  display: flex;
  flex-direction: column;
}

.checkbox--xs .checkbox__text {
  margin-left: var(--space-md);
}

.checkbox--sm .checkbox__text {
  margin-left: var(--space-md);
}

.checkbox--md .checkbox__text {
  margin-left: var(--space-md);
}

.checkbox__label_text {
  display: inline-block;
  color: var(--color-gray-700);
  font-family: var(--font-family-body);
  font-style: normal;
  font-weight: var(--font-weight-small);
}

.checkbox--xs .checkbox__label_text {
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
  
}

.checkbox--sm .checkbox__label_text {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.checkbox--md .checkbox__label_text {
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
}

.checkbox__supporting_text {
  color: var(--color-gray-600);
  font-family: var(--font-family-body);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-sm);
  margin-top: var(--space-xxs);
}

.checkbox__input:checked + .checkbox__label .checkbox__control {
  background-color: var(--color-gray-700);
  border-color: var(--color-gray-700);
}

.checkbox__input:not(:disabled):checked:hover
  + .checkbox__label
  .checkbox__control,
.checkbox__input:checked:focus + .checkbox__label .checkbox__control {
  background-color: var(--color-gray-800);
  border-color: var(--color-gray-800);
}

.checkbox__input:indeterminate + .checkbox__label .checkbox__control {
  background-color: var(--color-gray-700);
  border-color: var(--color-gray-700);
}

.checkbox--hover .checkbox__control,
.checkbox__input:not(:disabled):checked:hover
  + .checkbox__label
  .checkbox__control {
  border-color: var(--color-gray-800);
}

.checkbox--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkbox--focus .checkbox__control {
  box-shadow: 0 0 0 2px var(--brand-100);
}
