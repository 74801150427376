/* Achieve moduel: Issue Overview Part */
.IssueOverviewArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: calc(100vw - 52px);
    margin-top: 1px;
    margin-right: 3px;
    min-height: calc(100vh - 42px);
    max-height: calc(100vh - 42px);
    padding: 24px;
    gap: 12px;
    flex: 1 0 0;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
}

/* the scorll bar of dashboard area*/
.IssueOverviewArea::-webkit-scrollbar {
  width: 7px !important;
}

.IssueOverviewArea::-webkit-scrollbar-track {
  background: white !important;
}

.IssueOverviewArea::-webkit-scrollbar-thumb {
  background: var(--color-gray-300);
  border-radius: 4px;
}

/* the issue overview page tittle  */
.issueDetailsTitleArea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 8px 0px;
    gap: 12px;
    box-sizing: border-box;
    width: 100%;
}

/* the issue overview page title (left Area) */
.issueDetailsTitleAreaLeft{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
    white-space: nowrap; /* not change text line */
    overflow: hidden; /* hideen the over size part */
}

/* the issue overview page title (left) */
.issueDetailsTitleAreaLeft .pageTitle{
    font-size: var(--font-size-md);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-md); /* 150% */
    color: var(--color-gray-950);
}

/* the issue overview page title (left) */
.issueDetailsTitleAreaLeft .pageSubTitle{
    font-size: var(--font-size-sm);
    font-style: normal;
    font-weight: 400;
    line-height: var(--line-height-sm); /* 150% */
    color: var(--color-gray-600);
}

/* the issue overview page tittle (right dropdown menu) */
.issueDetailsTitleAreaRight{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    flex: 1 0 0;
}

/* the dynamic time line Tab button area */
.issueDetailsTitleAreaRight .timeLineArea {
    display: flex;
    padding: 4px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--color-dark-gray-50);
}

/*the bar chart, pie chart area*/
.chartOverview{
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
}

.locationChart{
  display: flex;
  margin-top: 12px;
  width: 100%;
}

/* the issue overview chart area */
.issueOverviewChart{
  display: flex;
  margin-top: 12px;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
}

