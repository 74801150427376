/* the module 3: Achieve module page css file */
@import "../../App.css";

.achieveModule{
    display: flex;
    align-items: flex-start;
    font-family: var(--font-family-display);
}

/*the achieve module content area expect the sidebar*/
.achieveContentArea {
  display: flex;
  align-self: stretch;
  transition: width 0.5s;
  background-color: white;
  box-sizing: border-box;
  transition: width 0.4s ease;
}

/* used for every page display the route */
.pageRoute{
    display: flex;
    align-items: center;
    gap: 4x;
}

/* the route button of each Achieve page */
.routeButton {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    color: var(--Colors-Gray-600, #475467);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-xs);
    background: transparent;
}

.routeButton.isClicked{
    border-radius: 2px;
    background: var(--color-dark-gray-100);
}

.routeButton.DisableClicked{
    border-radius: 2px;
    pointer-events: none
}