/*The pop window used for share, save and hidden */
.TablePopWindow {
  position: absolute; /* the absolute position */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-white);
  width: 160px;
  border-radius: var(--radius-xs);
  border: 1px solid var(--color-gray-200);
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  z-index: 1000;
  padding: var(--space-lg) 6px;
  font-family: var(--font-family-display) !important;
  gap: 2px;
}

.TablePopWindow .selectText {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-sm);
  color: var(--brand-secondary-500);
  padding: 4px 8px;
}

.TablePopWindow .mainSelectButton {
  display: flex;
  height: 32px !important;
  width: 160px !important;
  font-family: var(--font-family-display);
  background-color: var(--color-white);
  justify-content: space-between !important;
  align-items: center;
  align-self: stretch;
  height: 40px;
  padding: 9px 10px;
  color: var(--color-gray-700);
  border: 1px solid transparent;
  border-radius: 4px;
}

.TablePopWindow .mainSelectButton:hover {
  border-color: #2ED3B7;
  background: #F0FDF9;
}

.TablePopWindow .mainSelectButton:active {
  border-color: #2ED3B7;
  background: #F0FDF9;;
}

/* if clicked once, will change background color */
.TablePopWindow  .mainSelectButton.clicked{
  border-color: #2ED3B7;
  background: #F0FDF9;;
}

.leftPart{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.subMenu{
  display: flex;
  width: 168px;
  padding: 8px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  background-color: white;
  border-radius: 4px;
  z-index: 1000;
  border: 1px solid var(--Gray-200, #E4E7EC);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.subMenu .dropDownHeader{
  display: flex;
  height: 30px;
  padding: 1px 3px;
  padding-bottom: 6px;
  align-items: center;
  align-self: stretch;
  gap: 10px;
  border-bottom: 1px solid #D0D5DD;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-sm);
}

.selectArea{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
  max-height: 200px !important;
}

/* the team member name list */
.subMenu .dropDownItem{
  display: flex; 
  height: 30px;
  padding: 6px 8px;
  align-items: center;
  align-self: stretch;
  gap: 10px;
}


.dropDownItem .tittleText{
  color: var(--color-gray-700);
  font-size: var(--font-size-sm);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-sm); /* 142.857% */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  color: var(--color-gray-500);
  font-size: var(--font-size-sm);
}

