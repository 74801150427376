/*the deatils pop window used for Achieve mdoule Trends page open and shows details */
.DetailsWindow{
    position: fixed;
    top: 0px;
    right: 0;
    padding: 24px;
    background: var(--color-base-white);
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.25);
    transition: transform 0.5s ease-in-out;
    z-index: 1000;
    max-height: 100vh;
    overflow-y: auto;
    gap: 12px;   
    display: flex;   
    flex-direction: column;  
    box-sizing: border-box;
    overflow-x: hidden;
    margin-left: 5px;
}

.DetailsWindow::-webkit-scrollbar {
  width: 5px;
}

.DetailsWindow::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-500);
  cursor: pointer;
  border-radius: 3px;
}

.DetailsWindow::-webkit-scrollbar-track {
  background-color: var(--color-gray-50);
}

.DetailsWindow::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-600);
}